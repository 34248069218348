import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44')
];

export const server_loads = [3,4,5,6,7,8];

export const dictionary = {
		"/": [9],
		"/auth": [~10,[2]],
		"/auth/sign-in": [~11,[2]],
		"/auth/sign-up": [~12,[2]],
		"/auth/verify-email": [~13,[2]],
		"/auth/verify-session": [~14,[2]],
		"/auth/verify-session/[sessionId]": [~15,[2]],
		"/core/actions": [18,[3]],
		"/core/audit": [~19,[3]],
		"/core/controls": [~20,[3]],
		"/core/controls/[id]": [~21,[3]],
		"/core/dashboard": [~22,[3]],
		"/core/datasets": [~23,[3]],
		"/core/(assignments)/documents": [~16,[3]],
		"/core/frameworks": [~24,[3]],
		"/core/frameworks/[id]": [~25,[3]],
		"/core/models": [~26,[3]],
		"/core/models/[id]": [27,[3,4]],
		"/core/models/[id]/[ver]": [28,[3,4,5]],
		"/core/models/[id]/[ver]/assessments": [29,[3,4,5]],
		"/core/models/[id]/[ver]/reports": [30,[3,4,5]],
		"/core/models/[id]/[ver]/reports/explainability": [32,[3,4,5]],
		"/core/models/[id]/[ver]/reports/[rep]": [31,[3,4,5]],
		"/core/(assignments)/tests": [~17,[3]],
		"/join-org": [~33],
		"/org": [34,[6]],
		"/org/settings": [~35,[6]],
		"/org/switch-org": [~36,[6]],
		"/public-vendor": [~37],
		"/radar/applications": [~38,[7]],
		"/radar/dashboard": [~39,[7]],
		"/shield/frameworks": [~40,[8]],
		"/shield/frameworks/[id]": [~41,[8]],
		"/shield/questionnaires": [~42,[8]],
		"/shield/vendors": [~43,[8]],
		"/shield/vendors/[id]": [~44,[8]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';